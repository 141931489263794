<template>
  <div class="configurator h-100 container-fluid">
    <div class="section-container h-100" v-if="!b2cError && !isB2cFetching">
      <!-- parte destra -->
      <div class="row h-100">
        <div class="col-md-6 h-100" id="left-side">
          <img class="ceasy-logo img-fluid" src="@/assets/logo_customeasy_oriz.png" />

          <div class="row h-100 justify-content-center align-items-center">
            <div class="col d-flex flex-wrap justify-content-center align-items-center">
              <p class="dealer-name" v-if="!b2cDealer.logo">
                {{ b2cDealer.name }}
              </p>
              <img class="img-fluid" v-if="b2cDealer.logo" :src="b2cDealer.logo" />
            </div>
          </div>
        </div>
        <!-- parte sinistra -->
        <div class="col-md-6 config-section h-100">
          <div class="row h-100 justify-content-center align-items-center">
            <div class="col-4 d-flex flex-wrap justify-content-center align-items-center">
              <router-link
                v-if="loggedUser === null"
                class="start-btn Button Button__create"
                :to="{ name: 'B2C_configurator', params: { token: $route.params.token } }"
              >
                <Palette /> START
              </router-link>
              <button v-if="loggedUser !== null" class="start-btn Button Button__create" v-b-modal="'loggedUserAlert'">
                <Palette /> START
              </button>
              <b-modal v-if="loggedUser !== null" id="loggedUserAlert" hide-header hide-footer centered size="lg">
                <div class="text-danger text-center" style="font-size: 50px;"><Alert /></div>
                <div class="text-center">
                  <p>Per accedere al portale CustomEasy dedicato ai clienti esterni devi prima eseguire il logout!</p>
                  <p>Please log out to access the CustomEasy portal for external customers</p>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row start-left-side h-100" v-if="isB2cFetching">
      <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col text-center">
            <img id="sparco-logo-loading" :src="logoCEasy" />
            <Loader />
          </div>
        </div>
      </div>
    </div>
    <div v-if="b2cError && !isB2cFetching">
      <div class="mt-5 pt-5">
        <ErrorMessage />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import logoCEasy from '@/assets/logo_customeasy_oriz_white.png'
import Palette from 'vue-material-design-icons/Palette.vue'
import Alert from 'vue-material-design-icons/Alert.vue'

export default {
  name: 'b2clanding',
  components: {
    Loader,
    ErrorMessage,
    Palette,
    Alert
  },
  data() {
    return {
      logoCEasy
    }
  },
  computed: {
    ...mapGetters(['isB2cFetching', 'b2cError', 'b2cDealer', 'loggedUser'])
  },
  created() {
    this.$store.dispatch('B2C_GET_DEALER', this.$route.params.token).then(result => {
      if (result === false) {
        this.$router.push({ path: '/404' })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.dealer-name {
  font-size: 50px;
  color: $c-blue;
  text-transform: capitalize;
}
.configurator {
  background-color: $c-white;
  overflow: hidden;

  .start-loader {
    .Loader {
      background: transparent;
    }
  }

  .Loader {
    background: rgba($c-white, 0.4);
  }

  .ceasy-logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 50%;
    max-width: 350px;
  }

  .config-section {
    background: linear-gradient(45deg, $c-blue 0%, $c-lightblue 100%);
  }

  .start-btn {
    font-size: 25px;
    width: 100%;
    padding: 15px;
    height: auto !important;
    color: $c-blue;
    text-align: center;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
      color: $c-blue;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to .fade-leave-active {
    opacity: 0;
  }
}
</style>
